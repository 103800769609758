<template>
  <div>
    <b-overlay
      :show="isLoading"
      opacity="0.70"
      variant="transparent"
      rounded="sm"
    >
      <b-card
        v-if="$can('read', 'dashboard.teacher') && !$can('manage', 'all')"
        no-body
        class="rounded"
      >
        <app-collapse>
          <app-collapse-item title="Nova postagem">
            <b-form-group
              label="Titulo:"
              label-for="i-company"
              class="pl-1 pr-1"
            >
              <b-form-input
                v-model="dataMural.name"
                placeholder="Titulo"
              />
            </b-form-group>
            <b-form-group
              label="Descrição:"
              label-for="i-company"
              class="pl-1 pr-1"
            >
              <input
                id="file"
                type="file"
                hidden
                @change="uploadFunction"
              >
              <b-overlay
                :show="isLoadingQuill"
                opacity="0.70"
                variant="transparent"
                rounded="sm"
              >
                <quill-editor
                  ref="quillEdit"
                  v-model="dataMural.description"
                  :options="editorOption"
                />
              </b-overlay>
            </b-form-group>
            <b-row>
              <b-col>
                <b-button
                  variant="relief-primary"
                  block
                  @click="addPostMural"
                >
                  Postar
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  variant="relief-danger"
                  block
                  @click="clearPost"
                >
                  Cancelar
                </b-button>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-card>
      <div>
        <b-link
          v-for="(tr, indextr) in murals"
          id="listMural"
          :key="indextr"
          :to="{ path: '/mural/' + tr.id }"
          @click="saveMuralId(tr.id)"
        >
          <b-card>
            <div class="d-flex justify-content-start align-items-center mb-1">
              <b-avatar
                :src="tr.user.photo"
                size="50"
                class="mr-1"
              />
              <div class="profile-user-info">
                <h6 class="mb-0">
                  {{ tr.user.name }}
                </h6>
                <small class="text-muted">{{
                  tr.created_at | dateFormat
                }}</small>
              </div>
            </div>
            <b-card-text v-html="tr.description" />
            <template #footer>
              <div
                v-if="$can('read', 'dashboard.teacher') && !$can('manage', 'all') || user.id == tr.user.id"
                class="text-link"
              >
                <b-link
                  v-b-modal.modal-update
                  @click="uploadMural(tr.id, tr.name, tr.description)"
                >
                  Editar
                </b-link> | <b-link @click="deleteMural(tr.id)">
                  Apagar
                </b-link>
              </div>
            </template>
          </b-card>
          <b-card v-show="murals.length == 0">
            <div class="text-center">
              <!-- <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm"> -->
              <h2>Nenhuma postagem no mural</h2>
              <b />
              <p class="mt-2">
                <b-img
                  fluid
                  width="450"
                  :src="require('@/assets/images/pages/post.svg')"
                />
              </p>
              <!-- </b-overlay> -->
            </div>
          </b-card>
        </b-link>
        <div class="row">
          <div class="col-md">
            <b-pagination
              v-model="currentPage"
              class="text-center float-right"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="listMural"
              size="sm"
              @change="handlePageChange"
            />
          </div>
        </div>
      </div>

      <b-card v-show="murals.length == 0">
        <div class="text-center">
          <h2>Nenhuma publicação foi postada</h2>
          <b> Aguarde alguém fazer a primeira publicação! </b>
          <p class="mt-2">
            <b-img
              fluid
              width="450"
              :src="require('@/assets/images/pages/publish_post.svg')"
            />
          </p>
        </div>
      </b-card>

      <b-modal
        id="modal-update"
        ref="modal-update"
        centered
        title="Atualizar postagem"
        size="lg"
        ok-only
        ok-title="Accept"
      >
        <b-card-text>
          <b-form-group
            label="Titulo:"
            label-for="i-company"
            class="pl-1 pr-1"
          >
            <b-form-input
              v-model="dataMuralUpdate.name"
              placeholder="Titulo"
            />
          </b-form-group>
          <b-form-group
            label="Descrição:"
            label-for="i-company"
            class="pl-1 pr-1"
          >
            <input
              id="file"
              type="file"
              hidden
              @change="uploadFunction"
            >
            <b-overlay
              :show="isLoadingQuill"
              opacity="0.70"
              variant="transparent"
              rounded="sm"
            >
              <quill-editor
                ref="quillEdit"
                v-model="dataMuralUpdate.description"
                :options="editorOption"
              />
            </b-overlay>
          </b-form-group>
        </b-card-text>
        <template #modal-footer>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class=""
              variant="outline-primary"
              block
              @click="updateMural()"
            >
              Alterar postagem
            </b-button>
          </b-col>
        </template>
      </b-modal>
    </b-overlay>
  </div>
</template>
<script>
/* eslint-disable */
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import axios from "@axios";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    quillEditor
  },
  directives: {
    Ripple,
  },
  created(){
    this.fetchMural();
  },
  computed: {
    ...mapState("murals", ["murals"]),
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
  data() {
    
    return {
      isLoading: false,
      isLoadingQuill: false,
      dataMural: {
        name: null,
        description: null,
        class_id: localStorage.getItem('classId'),
      },
      
      dataMuralUpdate: {
        id: null,
        name: null,
        description: null,
        class_id: localStorage.getItem('classId'),
      },

      selectedFile: null,

      perPage: 5,
      currentPage: 1,
      totalRows: null,

      // editorOption: {
      //   placeholder: 'Insira o texto aqui',
      // },

      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{ 'indent': '-1'}, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'font': [] }],
              [{ 'align': [] }],
              ['image', 'link'] 
            ],
            handlers: {
              image: function() {
                document.getElementById('file').click()
              }
            }
          }
        }
      },
    };
  },
  methods: {
    ...mapActions("murals", ["store", "delete", "update"]),
    uploadFunction(e){
      this.isLoadingQuill = true;
      this.selectedFile = e.target.files[0];
      var form = new FormData();
      form.append("image", this.selectedFile);

      //upload image to server
      axios.post('image-upload', form,{
        'headers': {
            'Content-Type': "multipart/form-data"
        }
      })
      .then(r => {

        //this code to set your position cursor
        const range = this.$refs.quillEdit.$refs.editor.__quill.getSelection()
        this.$refs.quillEdit.$refs.editor.__quill.insertEmbed(range.index , 'image', r.data)
        this.isLoadingQuill = false
      })
      .catch(e => {
        this.isLoadingQuill = false
      });
    },

    addPostMural() {
      this.isLoading = true
      this.store({
        ...this.dataMural
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Enviado com successo',
            icon: 'MessageSquareIcon',
            variant: 'success'
          }
        })
        this.clearPost()
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    saveMuralId(id){
      localStorage.removeItem('mural_id')
      localStorage.setItem(
        "mural_id", id
      )
    },

    uploadMural(id, name, description){
      this.dataMuralUpdate.id =  id,
      this.dataMuralUpdate.name = name,
      this.dataMuralUpdate.description = description
    },

    updateMural(){
      this.isLoading = true
      this.update({
        ...this.dataMuralUpdate
      })
      .then(() => {
        this.isLoading = false
        this.dataMuralUpdate.id = null,
        this.dataMuralUpdate.name = null,
        this.dataMuralUpdate.description = null,
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Enviado com successo',
            icon: 'MessageSquareIcon',
            variant: 'success'
          }
        })
        this.$bvModal.hide('modal-update')
      })
      .finally(() => {
        this.dataMuralUpdate.id = null,
        this.dataMuralUpdate.name = null,
        this.dataMuralUpdate.description = null,
        this.isLoading = false
      })
    },

    deleteMural(id){
      this.isLoading = true
      this.delete({
        id
      })
      .then(() => {
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deletado com sucesso',
            icon: 'MessageSquareIcon',
            variant: 'success'
          }
        })
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    clearPost(){
      this.dataMural.name = null
      this.dataMural.description = null
    },

    fetchMural(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("murals/fetchMurals", {
          paginate: paginated,
          page: page,
          per_page: this.perPage
        })
        .then((response) => {
          this.isLoading = false
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handlePageChange(value) {
      this.fetchMural(true, value)
    },
  },
  watch: {
    perPage(){
      this.fetchMural()
    }
  },
}


</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.comments-style {
  width: 100%;
  height: 255px;
}
.comments-public-style {
  width: 100%;
  height: 255px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}
</style>